.container {
  max-width: 320px;
}

.heading {
  margin: 10px 0 0;
  font-size: 50px;
  line-height: 50px;
}

.divider {
  display: block;
  width: 38px;
  height: 3px;
  background-color: #1B2956;
  margin: 10px 0 7px;
}

.subhead,
.posthead {
  margin: 0;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.5px;
  font-weight: 700;

  & abbr {
    text-decoration: none;
  }
}

.rsvpBtn {
  display: flex;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 30px;
  font-size: 14px;
  line-height: 20px;
  margin-top: 5px;
  background-color: #1B2956;
  color: #FFFDF4;
  font-weight: 700;
  letter-spacing: 1px;

  &:hover {
    background-color: #2745A5;
  }
}