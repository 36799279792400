@keyframes rightCloudIn {
  from {
    transform: translateX(20%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes leftCloudIn {
  from {
    transform: translateX(-220%);
  } to {
    transform: translateX(-200%);
  }
}

@keyframes peakSun {
  from {
    transform: translateX(20%) translateY(20%);
  }
  to {
    transform: trnaslate(0);
  }
}

.container {
  position: fixed;
  left: 0;
  width: 100%;
  bottom: 30px;
  z-index: -1;
  transition: all 0.3s;

  &.passive {
    opacity: 0.3;
    transform: scale(1.4)translateY(20vh);

    & .couple {
      transform: translateX(30%)translateY(100%);
    }
  }

  & img {
    border: 0 none;
    display: block;
  }
}

.mountain {
  position: absolute;
  bottom: 20px;

  &.left {
    left: 0;
  }

  &.right {
    right: 0;
  }

  &.middle {
    left: 50%;
    transform: translateX(-50%);
  }
}

.couple {
  position: absolute;
  right: 40px;
  bottom: 0;
  transition: transform 0.3s;
}

.sun {
  position: absolute;
  bottom: 180px;
  left: 20px;
  animation: peakSun 10s infinite alternate-reverse linear;
}

.cloud {
  position: absolute;
  bottom: 260px;

  &.right {
    right: 15px;
    animation: rightCloudIn 10s infinite alternate-reverse linear;
  }

  &.left {
    left: 50%;
    transform: translateX(-200%);
    animation: leftCloudIn 10s infinite alternate-reverse linear;
  }
}