.pagewrap {
  padding: 40px;
  
  @media (max-width: 375px) {
    padding: 40px 30px;
  }
}

.main-nav {
  display: flex;
  margin-bottom: 30px;
  text-transform: uppercase;

  & a {
    display: block;
    padding-top: 7px;
    border-top: solid 3px transparent;
    text-decoration: none;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.5px;

    &.active-route {
      border-top-color: #1B2956;
    }

    &,
    &:hover,
    &:visited {
      color: #1B2956;
    }

    & + a {
      margin-left: 30px;

      @media (max-width: 375px) {
        margin-left: 20px;
      }
    }
  }
}
