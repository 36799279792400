*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Nunito', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #1b2956;
  background-color: #fffdf4;
}

h1 {
  font-family: utopia-std-display, serif;
  font-weight: 700;
}

button,
input[type='submit'] {
  color: #fff;
  background-color: #1b2956;
  appearance: none;
  border: 0 none;
  font-family: 'Nunito', sans-serif;
  transition: background-color 0.1s;

  &:hover {
    background-color: #2745a5;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
