*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Nunito', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #1b2956;
  background-color: #fffdf4;
}

h1 {
  font-family: utopia-std-display, serif;
  font-weight: 700;
}

button,
input[type='submit'] {
  color: #fff;
  background-color: #1b2956;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: 0 none;
  font-family: 'Nunito', sans-serif;
  -webkit-transition: background-color 0.1s;
  transition: background-color 0.1s
}

button:hover, input[type='submit']:hover {
    background-color: #2745a5;
  }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.copy_container__1IbP0 {
  max-width: 320px;
}

.copy_heading__1CiUw {
  margin: 10px 0 0;
  font-size: 50px;
  line-height: 50px;
}

.copy_divider__1N-gh {
  display: block;
  width: 38px;
  height: 3px;
  background-color: #1B2956;
  margin: 10px 0 7px;
}

.copy_subhead__2E9U_,
.copy_posthead__2WuQV {
  margin: 0;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.5px;
  font-weight: 700
}

.copy_subhead__2E9U_ abbr, .copy_posthead__2WuQV abbr {
    text-decoration: none;
  }

.copy_rsvpBtn__30ZRk {
  display: flex;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 30px;
  font-size: 14px;
  line-height: 20px;
  margin-top: 5px;
  background-color: #1B2956;
  color: #FFFDF4;
  font-weight: 700;
  letter-spacing: 1px
}

.copy_rsvpBtn__30ZRk:hover {
    background-color: #2745A5;
  }

  .accommodations_hotel__3ZTvV b {
    font-size: 20px;
    line-height: 25px;
    font-family: utopia-std-display, serif;
    font-weight: 700;
  }

.accommodations_hotel__3ZTvV span {
    font-weight: 400;
  }

.accommodations_hotel__3ZTvV b,
  .accommodations_hotel__3ZTvV span {
    display: block;
  }

.accommodations_hotel__3ZTvV a {
    text-decoration: none
  }

.accommodations_hotel__3ZTvV a, .accommodations_hotel__3ZTvV a:visited {
      color: inherit;
    }

.accommodations_hotelList__35oVp {
  display: grid;
  max-width: 512px;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 50px;
  grid-row-gap: 20px
}

@media (max-width: 560px) {

.accommodations_hotelList__35oVp {
    grid-template-columns: repeat(1, 1fr)
}
  }
@-webkit-keyframes background_rightCloudIn__1BBsc {
  from {
    -webkit-transform: translateX(20%);
            transform: translateX(20%);
  }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes background_rightCloudIn__1BBsc {
  from {
    -webkit-transform: translateX(20%);
            transform: translateX(20%);
  }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@-webkit-keyframes background_leftCloudIn__3T0w7 {
  from {
    -webkit-transform: translateX(-220%);
            transform: translateX(-220%);
  } to {
    -webkit-transform: translateX(-200%);
            transform: translateX(-200%);
  }
}

@keyframes background_leftCloudIn__3T0w7 {
  from {
    -webkit-transform: translateX(-220%);
            transform: translateX(-220%);
  } to {
    -webkit-transform: translateX(-200%);
            transform: translateX(-200%);
  }
}

@-webkit-keyframes background_peakSun__1vABX {
  from {
    -webkit-transform: translateX(20%) translateY(20%);
            transform: translateX(20%) translateY(20%);
  }
  to {
    -webkit-transform: trnaslate(0);
            transform: trnaslate(0);
  }
}

@keyframes background_peakSun__1vABX {
  from {
    -webkit-transform: translateX(20%) translateY(20%);
            transform: translateX(20%) translateY(20%);
  }
  to {
    -webkit-transform: trnaslate(0);
            transform: trnaslate(0);
  }
}

.background_container__3dD8h {
  position: fixed;
  left: 0;
  width: 100%;
  bottom: 30px;
  z-index: -1;
  -webkit-transition: all 0.3s;
  transition: all 0.3s
}

.background_container__3dD8h.background_passive__3kBVy {
    opacity: 0.3;
    -webkit-transform: scale(1.4)translateY(20vh);
            transform: scale(1.4)translateY(20vh)
  }

.background_container__3dD8h.background_passive__3kBVy .background_couple__3AM1o {
      -webkit-transform: translateX(30%)translateY(100%);
              transform: translateX(30%)translateY(100%);
    }

.background_container__3dD8h img {
    border: 0 none;
    display: block;
  }

.background_mountain__3gpLF {
  position: absolute;
  bottom: 20px
}

.background_mountain__3gpLF.background_left__Iqai6 {
    left: 0;
  }

.background_mountain__3gpLF.background_right__2gnRs {
    right: 0;
  }

.background_mountain__3gpLF.background_middle__NWvak {
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }

.background_couple__3AM1o {
  position: absolute;
  right: 40px;
  bottom: 0;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}

.background_sun__1plDs {
  position: absolute;
  bottom: 180px;
  left: 20px;
  animation: background_peakSun__1vABX 10s infinite alternate-reverse linear;
}

.background_cloud__1Umbg {
  position: absolute;
  bottom: 260px
}

.background_cloud__1Umbg.background_right__2gnRs {
    right: 15px;
    animation: background_rightCloudIn__1BBsc 10s infinite alternate-reverse linear;
  }

.background_cloud__1Umbg.background_left__Iqai6 {
    left: 50%;
    -webkit-transform: translateX(-200%);
            transform: translateX(-200%);
    animation: background_leftCloudIn__3T0w7 10s infinite alternate-reverse linear;
  }
.pagewrap {
  padding: 40px
}

@media (max-width: 375px) {

.pagewrap {
    padding: 40px 30px
}
  }

.main-nav {
  display: flex;
  margin-bottom: 30px;
  text-transform: uppercase
}

.main-nav a {
    display: block;
    padding-top: 7px;
    border-top: solid 3px transparent;
    text-decoration: none;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.5px
  }

.main-nav a.active-route {
      border-top-color: #1B2956;
    }

.main-nav a,
    .main-nav a:hover,
    .main-nav a:visited {
      color: #1B2956;
    }

.main-nav a + a {
      margin-left: 30px
    }

@media (max-width: 375px) {

.main-nav a + a {
        margin-left: 20px
    }
      }

