.hotel {
  & b {
    font-size: 20px;
    line-height: 25px;
    font-family: utopia-std-display, serif;
    font-weight: 700;
  }

  & span {
    font-weight: 400;
  }

  & b,
  & span {
    display: block;
  }

  & a {
    text-decoration: none;
    
    &, &:visited {
      color: inherit;
    }
  }
}

.hotelList {
  display: grid;
  max-width: 512px;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 50px;
  grid-row-gap: 20px;

  @media (max-width: 560px) {
    grid-template-columns: repeat(1, 1fr);
  }
}